const messages = {
    en: {
      message: {
        first_name: 'First Name',
        last_name: 'Last Name',
        login: 'Log In',
        email: 'Email',
        password: 'Password',
        confirm_password: 'Confirm password',
        signup: 'Sign Up',
        or: 'Or',
        login_with_google: 'Log in with Google',
        empty_email: 'Please enter your email',
        emailNotValid: 'This email is not valid',
        emptyPassword: 'Please enter your password',
        emptyFirstName: 'Please enter your first name',
        emptyLastName: 'Please enter your last name',
        passwordLength: 'Min 8 characters',
        passwordMatch: 'Passwords don\'t match',
        register_new_account: 'Don\'t have an account? Sign up!',
        have_account_login: 'Already have an account? Log in.',
        welcome_l0: '',
        welcome_l1: 'Welcome to TOPS (Taleb OCR Publice Service)',
        welcome_l2: 'Convert your PDF files to text and searchable PDF files.',
        welcome_l3: 'To use our service you need to sign in and allow us to share your files on mtaleb.net website for public.',
        book_info: 'Book ID information',
        title: 'Title',
        volume: 'Volume',
        author: 'Author',
        death_year: 'Author\'s death year',
        publisher: 'Publisher',
        researcher: 'Researcher',
        tags: 'Tags',
        pdf_file: 'PDF File',
        select_pdf_file: 'Select a PDF file',
        arabic: 'Arabic',
        english: 'English',
        farsi: 'Farsi',
        terms_conditions: 'By using this service, you grant us the permission to share your file on mtaleb.net website for public.',
        submit_ocr: 'Submit OCR Request',
        empty_title: 'Please enter book title',
        empty_author: 'Please enter book author\'s name',
        empty_file: 'Please select a pdf file',
        ocr_response_file_received_and_queued: 'Thanks. We have recieved your file successfully and the download link will be sent via email when conversion is completed. The process takes between 10 to 20 minutes depending on the file size. If the server is busy, your files will be queued in the waiting list.',
        ocr_response_account_not_activated: 'The email address has not been confirmed. Please check your mailbox.',
        dismiss: 'Dismiss',
        confirm_email_notification: 'A confirmation message has been sent to the mailbox. Please open the message and continue with the confirmation process.',
        not_activated_yet_notification: 'Seems like you haven\'t activated your account yet!',
        resend_email: 'Resend Email',
      }
    },
    ar: {
      message: {
        first_name: 'الاسم الأول',
        last_name: 'اسم العائلة',
        login: 'تسجيل الدخول',
        email: 'بريد إلكتروني',
        password: 'كلمه المرور',
        confirm_password: 'تأكيد كلمة المرور',
        signup: 'تسجيل حساب',
        register_new_account: 'ليس لديك حساب؟ تسجيل!',
        have_account_login: 'هل لديك حساب؟ تسجيل الدخول.',
        or: 'أو',
        login_with_google: 'تسجيل الدخول عبر جوجل',
        empty_email: 'رجاء أدخل بريدك الإلكتروني',
        emailNotValid: 'هذا البريد الإلكتروني غير صالح',
        emptyPassword: 'رجاء ادخل كلمة المرور.',
        emptyFirstName: 'رجاء ادخل اسمك الاول',
        emptyLastName: 'رجاء ادخل اسمك العائلة',
        passwordLength: '8 أحرف على الأقل',
        passwordMatch: 'كلمات المرور غير متطابقة',
        welcome_l0: 'TOPS: Taleb OCR Publice Service',
        welcome_l1: ' أهلاً وسهلاً بكم في خدمة القارئ الآلي من مكتبة طالب العلم TOPS.',
        welcome_l2: 'يمكنكم هنا تبديل ملفات PDF إلى نص أو ملفات قابلة للبحث فيها (Searchable PDF).',
        welcome_l3: 'للإستفادة من هذه الخدمة يجب تسجيل الدخول وأيضاً منحنا الموافقة على نشر الكتاب في موقع مكتبة طالب العلم للعموم.',
        book_info: 'معلومات هوية الكتاب',
        title: 'إسم الكتاب',
        volume: 'الجزء',
        author: 'إسم المؤلف',
        death_year: 'سنة الوفاة / هجرية',
        publisher: 'الناشر',
        researcher: 'المحقق',
        tags: 'الموضوع',
        pdf_file: 'PDF File',
        select_pdf_file: 'Select a PDF file',
        arabic: 'عربي',
        english: 'إنجليزي',
        farsi: 'فارسي',
        terms_conditions: 'بإستخدام هذه الخدمة المجانية أوافق على نشر هذا الملف للعموم عبر مكتبة طالب العلم.',
        submit_ocr: 'بدء عملية التبديل',
        empty_title: 'الرجاء إدخال عنوان الكتاب.',
        empty_author: 'الرجاء إدخال اسم مؤلف الكتاب',
        empty_file: 'الرجاء تحديد ملف pdf',
        ocr_response_file_received_and_queued: 'شكرا لكم. تم إستلام الملف بنجاح وسوف يتم إرسال رابط التحميل عبر البريد بعد إنتهاء عملية التبديل. عملية التبديل تستغرق بين 10 إلى 20 دقيقة حسب حجم الملف. وفي حال وجود ضغط على الخدمة سيتم التعامل مع الملفات حسب قائمة الإنتظار.',
        ocr_response_account_not_activated: 'لم يتم تأكيد عنوان البريد الإلكتروني. يرجى مراجعة صندوق البريد.',
        dismiss: 'حسناً',
        confirm_email_notification: 'تم إرسال رسالة تأكيد إلى صندوق البريد. يرجى فتح الرسالة ومتابعة عملية التأكيد.',
        not_activated_yet_notification: 'لم تقم بتأكيد حسابك بعد!',
        resend_email: 'إعادة إرسال البريد الإلكتروني',
      }
    }
  }
  
const i18n = new VueI18n({
  locale: 'ar',
  fallbackLocale: 'en',
  messages,
})


export default i18n;