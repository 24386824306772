import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  rtl: true,
  icons: {
    iconfont: 'mdi',
  },
  theme:{
    themes: {
      light: {
        primary: "#20302e",
        secondary: "#99beba",
        tertiary: "#42A5F5",
        error: '#ff7a73',
      }
    }
  }
});
