import Vue from "vue";
import App from "./App";
import axios from "axios";

import vuetify from './plugins/vuetify';
import router from "./modules/router";
import auth from "./modules/auth";
import notifications from "./modules/notifications";
import i18n from './i18n.js'
// import VueMaterial from 'vue-material'
// import 'vue-material/dist/vue-material.min.css'

// Vue.use(VueMaterial)

Vue.config.productionTip = process.env.NODE_ENV === "production";
axios.defaults.withCredentials = true;

new Vue({
  router,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app');


Vue.mixin({
  data() {
    return {
      $_profile: auth.user.profile
    }
  }
});

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      notifications.addNotification('Session expired');
      auth.logout()
    }
    return Promise.reject(error);
  }
);
